<script>
import Header from '../components/header'

export default {
  name: "companyDetail",
  components: {Header},
}
</script>

<template>
<div>
  <Header />
  <div class="company-container">
    <div class="title">{{ $route.query.name }}</div>
    <div class="content">{{ $route.query.desc }}</div>
  </div>
</div>
</template>

<style scoped lang="scss">
.company-container {
  padding: 77px 17px 20px;
  color: #4c5460;

  .title {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 16px;
    text-align: center;
  }

  .content {
    font-size: 16px;
  }
}
</style>
